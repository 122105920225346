export const createMetaInfoObject = (array: [string, any][]) => {
  return array.reduce(
    (meta, item) => {
      if (!item[1]) return meta

      meta[item[0]] = item[1]
      return meta
    },
    {} as Record<string, string>,
  )
}
